.BrowserAlert * {
    margin: 0;
    padding: 0; }
  
.BrowserAlert {
    width: 90%;
    margin: auto;
    padding-top: 10vh;
    text-align: center; }

.BrowserAlert__text {
    text-align: center;
    margin-bottom: 5vh; }
    @media all and (min-width: 1024px) {
        .BrowserAlert__text {
        font-size: 28px;
        font-weight: 500; } }

.BrowserAlert__list {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column; }
    @media all and (min-width: 1024px) {
        .BrowserAlert__list {
        -webkit-flex-wrap: wrap;
        -webkit-flex-direction: row;
        flex-wrap: wrap;
        flex-direction: row;
        width: 75%;
        margin: auto; } }

.BrowserButton {
    -webkit-align-self: center;
    align-self: center;
    width: 70%;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    background-color: #fff;
    margin: 5px auto;
    box-shadow: 0 8px 16px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }
@media all and (min-width: 1024px) {
    .BrowserButton {
        width: calc(25% - 10px);
        padding: 2vh 0; } }
.BrowserButton:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.BrowserButton__img {
    padding: 0 2%; }

.BrowserButton__img img {
    width: 55px;
    height: 55px;
    margin: 0 10px 5px; }

.BrowserButton__text {
    text-align: left; }

.BrowserButton__text p:nth-child(2) {
    color: #535353; }