.admin-page-container {
    width: 100%;
}

.admin-body-container {
    padding: 1%;
    display: flex;
    flex-direction: row;
}

.admin-body-column {
    width: 50%;
    padding: 2%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.admin-calendar-handling-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.admin-filter-container {
    display: flex;
    width: 80%;
}

.admin-filter-dropdown {
    min-width: 20%;
}

.ayomi-calendar-button {
    flex-grow: 1;
}

.react-calendar {
    box-sizing: border-box;
    width: 100%;
}

.lighten-purple-background {
    background-color: #D4DAFC;
}
