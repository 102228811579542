@value colors: "../style/colors.css";
@value purple-lighten-5, base-dark-grey, base-purple, color-purple, color-error from colors;

.dropdown {
  cursor: pointer;
  position: relative;
  width: 40%;
  margin-left: 2%;
  margin-right: 2%;
}

.dropdown select {
  color: black;
}

.dropdown select:hover {
  cursor: pointer;
}

.dropdown .options {
  color: black;
  font-family: 'Roboto';
  display: block;
  padding: 8px;
}

.dropdown .options:hover {
  background-color: purple-lighten-5;
  cursor: pointer;
}

.icon {
  margin-right: 8px;
  transition: color .3s;
}

.iconAnimate {
    transform: rotateZ(0deg);
    animation: rotate 0.3s linear ;
    color: base-purple;
}

.inputOption {
  color: black;
  background-color: white;
}

.placeholder {
  color: base-dark-grey;
}

.optionContainer {
  width: 100%;
  padding: 8px;
  margin-top: 8px;

  box-shadow: -1px 7px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 1000;

  max-height: 150px;
  overflow: auto;
}

.optionContainer option {
  padding: 8px;
  border-radius: 5px;
}

.required {
  color: color-purple;
}

.error .hint {
  font-size: 12px;
  color: color-error;
}

@-webkit-keyframes rotate {
  from {transform: rotateZ(180deg);}
  to {transform: rotateZ(0deg);}
}
@-moz-keyframes rotate {
  from {transform: rotateZ(180deg);}
  to {transform: rotateZ(0deg);}
}
@keyframes rotate {
  from {transform: rotateZ(180deg);}
  to {transform: rotateZ(0deg);}
}
