.tdAndth {
    border: 1px solid rgb(190, 190, 190);
    padding: 10px;
}

.onlyTd {
    text-align: center;
}

.scrollit {
    overflow:scroll;
    height:500px;
}

/* tr:nth-child(even) {
    background-color: #eee;
}

th[scope="col"] {
    background-color: #696969;
    color: #fff;
}

th[scope="row"] {
    background-color: #d7d9f2;
} */
