@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


tr {
  height: 50px;
}

td {
  text-align: center;
  width: 50px;
}

.date {
  background-color: grey;
  color: white;
  border-radius: 25px;
  height: 40px;
  width: 40px;
  padding: 8px;
  size: 8px;
  text-align: center;
}
body *
{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}
.header
{
    font-size: 1.1025rem;
    color: #1D344E;
}
.header + form
{
    margin-top: 26px;
}
.scrim {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: black;
    opacity: 0.24;
}
.modal-wrap
{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 80%;
    padding: 32px 50px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12), 0px 6px 6px rgba(0, 0, 0, 0.24);
    max-width: 620px;
    z-index: 2;
}
.modal-wrap-short
{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 80%;
    padding: 32px 50px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12), 0px 6px 6px rgba(0, 0, 0, 0.24);
    max-width: 620px;
    z-index: 2;
    overflow:scroll;
    height:500px;
}

.modal-wrap-extended
{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: white;
    width: 80%;
    padding: 32px 50px;
    border-radius: 8px;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.12), 0px 6px 6px rgba(0, 0, 0, 0.24);
    max-width: 1024px;
    z-index: 2;
}

.modal-wrap-extended-mobile
{
    position: fixed;
    top: 0;
    left: 0;
    background: white;
    width: 100%;
    min-height: 100vh;
    padding: 24px;
    border-radius: 0;
    z-index: 2;
}

.react-tel-input input[type="text"],
.react-tel-input input[type="tel"]
{
    width: inherit !important;
}

.control-label
{
    display: block;
    font-size: 18px;
    color: #405a65;
    cursor: pointer;
    font-weight: 300;
}

.form-control
{
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
}

.form-control::placeholder
{
    color: #DADDE2;
}

.button, button[type='endform']
{
    background-color: #3A81F3;
    color: white;
    border-radius: 60px;
    border: 0px;
    font-size: 14.4px;
    padding: 12px 36px 11px 36px;
    margin-top: 35px;
    margin: 0 auto;
}

.phone-input
{
    width: 100% !important;
}

.flag-container .selected-flag {
    align-items: center !important;
    display: flex !important;
}

.flag-container .selected-flag .selected-dial-code {
    padding-left: 2px !important;
}

.form-error
{
    color: #E14646;
    border-color: #E14646;
}

.form-error::placeholder
{
    color: #E14646;
}

.title {
    font-size: 20px;
    text-align: center;
    margin-bottom: 16px;
}

.description {
    text-align: center;
}

.privacy-policy {
    display: flex;
    align-items: flex-start;
    margin-bottom: 16px;
}

.privacy-policy input {
    margin-right: 8px;
    margin-top: 4px;
}

.disabled-button {
    background-color: #ababab !important;
}

.loading-title {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    margin: auto auto 8px;
}

.loading-description {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    color: #8E8E8E;
    text-align: center;
    margin: auto;
}

.loading-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 16px;
    text-align: center;
    color: #8E8E8E;
}

.loading-row p, .loading-row span {
    font-size: 12px;
}

.loading-row p + p {
    margin-left: 24px;
}

.loading-icon {
    margin-right: 8px;
}

.v2-header {
    display: none;
}

.v2-header .rdv {
    font-weight: 700;
    margin-bottom: 0;
}

.v2-header .timezone {
    color: #8E8E8E;
    margin-top: 4px;
}

.loading-hint {
    text-align: center;
    font-weight: 700;
    color: red;
    margin-top: 16px;
    margin-bottom: 16px;
}

.resume {
    display: none;
}

.action {
    display: none;
}

.close {
    position: relative;
    width: 32px;
    height: 32px;
    opacity: 0.3;
    outline: none;
}

.close:hover, .close:focus {
    opacity: 1;
    outline: none;
    border: none;
}
.close:before, .close:after {
    position: absolute;
    top: -16px;
    content: ' ';
    height: 32px;
    width: 2px;
    background-color: #333;
}
.close:before {
    transform: rotate(45deg);
}
.close:after {
    transform: rotate(-45deg);
}

@media screen and (max-width: 426px) {
    .v2 .hide {
        display: none;
    }

    .v2 .modal-wrap-short {
        top: 0;
        left: 0;
        transform: unset;
        width: 100%;
        height: 100%;
        border-radius: 0;
        overflow-y: scroll;
    }

    .v2 .v2-header {
        width: 100%;
        display: flex;
        margin-top: 16px;
        flex-direction: row;
        justify-content: space-evenly;

        padding: 0 0 8px;
        border-bottom: 1px solid #d9d9d9;

        text-align: center;
    }

    .v2-header button {
        width: 32px;
        height: 32px;
        margin-right: 16px;

        border-radius: 48px;

        background-color: white;
        box-shadow: 0px 4px 13px 2px rgba(0, 0, 0, 0.13);

        border: none;
        color: #3A81F3;
    }

    .v2 .header {
        text-align: center;
    }

    .v2 .resume {
        display: block;
        margin-top: 32px;
        padding-bottom: 32px;
        border-bottom: 1px solid #d9d9d9;
    }

    .resume .icon {
        width: 32px;
        margin-right: 16px;
        color: #526AF2;
    }

    .v2 .button {
        width: 100%;
        margin-top: 16px;
    }

    .v2 .link {
        display: block;
        margin: 16px auto auto;
        text-align: center;
    }

    .v2 .action {
        display: block;
    }
}

body *
{
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
}

.banner
{
    background-color: #468FA7;
    color: white;
    padding: 1.2rem 1.87rem 2.18rem 1.87rem;
    background-image: url(/static/media/contact-bg.469a730f.webp);
    background-size: cover;
    min-height: 100vh;
    box-sizing: border-box;
}

.banner .title
{
    font-size: 1.25rem;
}
.banner .preface
{
    font-size: 1rem;
    margin-top: 1.125rem;
    margin-bottom: 2.5rem;
}
.banner .subtitle
{
    font-size: 1.5rem;
}

.go-pick
{
    font-size: 1.5rem;
    margin-top: 2.4rem;
    margin-bottom: 1.5rem;
    /* margin-left: 1.87rem; */
}

.calendar-landing
{
    padding: 2rem;
    min-width: 300px;
    color: rgba(0, 0, 0, 0.84);
}
.calendar-placeholder
{
    height: 50vh;
    background-color: gray;
}

.schedule-landing
{
    height: 100vh;
    padding: 2rem;
    border-left: 1px solid rgba(0, 0, 0, 0.11);
    color: rgba(0, 0, 0, 0.84);
}

.schedule-landing > div + div
{
    margin-top: 1rem;
}

.button-scroll-area
{
    height: calc(100% - 18rem);
    padding: 1rem;
    overflow-y: scroll;
}

.button
{
    display: block;
    width: 100%;
    margin-bottom: 1rem;
    padding: 1rem;
    border-radius: 4px;
}

.default-button
{
    background-color: white;
    border: 1px solid #1A9BD3;
    color: inherit;
}
.active-button
{
    background-color: #1A9BD3;
    border: none;
    color: white;
}

/* width */
.button-scroll-area::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.button-scroll-area::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.11);
    border-radius: 8px;
}

/* Handle */
.button-scroll-area::-webkit-scrollbar-thumb {
    background: #1A9BD3;
    border-radius: 8px;
}

.video-scroll-area
{
    background-color: rgba(0, 0, 0, 0.7);
    overflow-x: scroll;
    margin-top: 0.5rem;
    padding: 0.5rem;
}
.video-scroll-area > div
{
    padding-top: 0.5rem;
}
.video-scroll-area > div > * + *
{
    margin-left: 0.5rem;
}
.video-scroll-area::-webkit-scrollbar
{
    height: 10px;
}
.video-scroll-area::-webkit-scrollbar-thumb
{
    background-color: white;
    border-radius: 8px;
}
.video-scroll-area::-webkit-scrollbar-thumb:hover
{
    background-color: white;
}
.miniature
{
    display: inline-block;
    width: 7rem;
    height: 3rem;
    background-color: #293789;
    padding: 0.5rem;
}
.companies-logo
{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
}

/* Handle on hover */
/* ::-webkit-scrollbar-thumb:hover {
    background: #555;
} */

.recap
{
    position: relative;
    bottom: 0;
    padding: 1rem;
    padding-right: calc(1rem + 10px);
    border: 1px solid rgba(0, 0, 0, 0.39);
}

.submit-button
{
    margin-right: 10px;
}


.video-container
{
	position: relative;
	padding-bottom: 56.25%;
	padding-top: 30px;
	height: 100;
	overflow: hidden;
}

.video-container iframe,
.video-container object,
.video-container embed
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.banner p a
{
    color: white;
    text-decoration: underline;
}


.item-block
{
    display: block;
    height: 100%;
    width: 100%;
}
@media screen and (min-width: 768px)
{
#element-32
{
    top: 34.3125rem;
    left: 3.625rem;
    height: 3.5625rem;
    width: 3.4375rem;
    z-index: 9;
}
}
#element-32
{
    top: 83.25rem;
    left: 2.375rem;
    height: 3.692rem;
    width: 3.5625rem;
    z-index: 9;
}

.SliderVideo__steps {
    position: relative;
    bottom: -5px;
    display: flex;
    align-items: center;
    padding-bottom: 5%;
    justify-content: center; }
    @media all and (min-width: 1024px) {
        .SliderVideo__steps {
        bottom: -10px; } }

.SliderVideo__dot {
    width: 10px;
    height: 10px;
    border: solid 1px #fff;
    border-radius: 100%;
    cursor: pointer;
    transition: background-color 0.3s; }
    .SliderVideo__dot:nth-child(2) {
        margin: 0 15px; }
    .SliderVideo__dot--active {
        background-color: #fff; }

.calendly__footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  background-color: #f9f9f9;

  padding: 8px;
  margin-right: -15px;
  margin-left: -15px;
}

.calendly__footer__logo {
  font-family: Fjalla One, sans-serif;
  color: #333333;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 24px;
}

.calendly__footer a {
  color: #333333;
}

.calendly__footer a:hover {
  color: #1A9BD3;
  text-decoration: none;
}

.hint {
    margin-top: 24px;
    font-weight: 500;
}

.hint p {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

.hint_number {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-right: 8px;
    margin-bottom: 8px;

    width: 32px;
    min-width: 32px;
    height: 32px;

    border-radius: 32px;
    background-color: white;

    color: #1EABD9;
    font-weight: 700;
}

.hint_text { width: calc(100% - 40px); }

.stability {
    width: 100%;
    max-height: 100vh;
    overflow: hidden;
}

@media screen and (max-width: 1440px) {
    .stability {
        max-height: unset;
        overflow: unset;
    }
}

@media screen and (max-width: 768px) {
    .banner {
        display: none;
    }

    .stability {
        margin: auto;
    }
}

@media screen and (max-width: 426px) {
    .v2 .companies-logo { display: none; }

    .v2 .hide { display: none; }

    .v2 .button-scroll-area {
        overflow-y: unset;
        height: unset;
    }

    .v2 .go-pick { text-align: center; }

    .v2 .call { text-align: center; }

    .v2-container {
        width: 100%;
        padding: 32px 32px 0;
    }
}

/* Primary Color */

/* Accent Color */

/* Neutral color */

/* Feedback color */

/* Typography */

/* Border */

/* Forms */

/* Button */

.Loading_loadingContainer__4HrfJ {
  width: 100%;
  margin-bottom: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Loader css come from: https://loading.io/css/ */

.Loading_loading__2JaUi {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.Loading_loading__2JaUi div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #CCCCCC;
  border-radius: 50%;
  animation: Loading_loading__2JaUi 1.2s linear infinite;
}
.Loading_loading__2JaUi div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.Loading_loading__2JaUi div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.Loading_loading__2JaUi div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.Loading_loading__2JaUi div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.Loading_loading__2JaUi div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.Loading_loading__2JaUi div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.Loading_loading__2JaUi div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.Loading_loading__2JaUi div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.Loading_loading__2JaUi div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.Loading_loading__2JaUi div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.Loading_loading__2JaUi div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.Loading_loading__2JaUi div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@keyframes Loading_loading__2JaUi {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}

.timezone__container {
    position: relative;

    width: 50%;
    min-width: -moz-fit-content;
    min-width: fit-content;
}

.timezone__button {
    background: none;
    border: none;
    padding: 8px 12px 8px 8px;
    border-radius: 4px;
    transition: .3s;

    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    background-color: rgba(175, 191, 222, 0.2);
}

.timezone__button:hover, .timezone__dropdown:focus {
    cursor: pointer;
}

.timezone__icon {
    margin-right: 8px;
}

.timezone__dropdown {
    margin-left: 8px;
}

.timezone__list {
    padding: 8px;
    width: 100%;
    box-sizing: border-box;
    margin-top: 8px;
    border-radius: 4px;

    background-color: rgba(175, 191, 222, 0.2);
}

.timezone__country {
    max-height: 100px;
    overflow-y: scroll;

    display: flex;
    flex-direction: column;
    scrollbar-color: rgba(0, 0, 0, 0.1) transparent; /* thumb and track color */
    scrollbar-width: thin;
}

.timezone__country::-webkit-scrollbar {
    width: 5px;  /* for vertical scrollbars */
}

.timezone__country::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}

.timezone__searchbar {
    background-color: white;
    margin-bottom: 8px;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 2px solid white;
    transition: .3s;
}

.timezone__searchbar:focus-within, .timezone__searchbar:hover {
    border-color: #1A9BD3;
    box-sizing: border-box;
}

.timezone__searchbar:focus-within .timezone__icon,
.timezone__searchbar:hover .timezone__icon {
    color: #1A9BD3;
}

.timezone__input {
    width: 100%;
}

.timezone__input {
    border: none;
    outline: none;
}

.timezone__clear {
    background: none;
    border: none;
    color: #1A9BD3;
    transition: .3s;
}

.timezone__clear:hover {
    color: #007bff;
    cursor: pointer;
}

.timezone__city {
    background: none;
    border: none;
    outline: none;

    padding: 8px;
    border-radius: 4px;
    box-sizing: border-box;
}

.timezone__city:hover {
    background-color: #1A9BD3;
    color: white;
}

.calendly__calendar__date_available {
    background-color: rgba(175, 191, 222, 0.2);
    color: #53a3fd;
    border-radius: 25px;
    height: 40px;
    width: 40px;
    margin: auto;
    padding: 8px;
    size: 8px;
    text-align: center;
    cursor: pointer;
}

.calendly__calendar__date_clicked {
    background-color: rgba(175, 191, 222, 0.9);
    color: #53a3fd;
    border-radius: 25px;
    height: 40px;
    width: 40px;
    margin: auto;
    padding: 8px;
    size: 8px;
    text-align: center;
    cursor: pointer;
}

.calendly__calendar__date_unavailable {
    color: #b0b0b0;
    border-radius: 25px;
    height: 40px;
    width: 40px;
    margin: auto;
    padding: 8px;
    size: 8px;
    text-align: center;
}
.BrowserAlert * {
    margin: 0;
    padding: 0; }
  
.BrowserAlert {
    width: 90%;
    margin: auto;
    padding-top: 10vh;
    text-align: center; }

.BrowserAlert__text {
    text-align: center;
    margin-bottom: 5vh; }
    @media all and (min-width: 1024px) {
        .BrowserAlert__text {
        font-size: 28px;
        font-weight: 500; } }

.BrowserAlert__list {
    display: flex;
    flex-direction: column; }
    @media all and (min-width: 1024px) {
        .BrowserAlert__list {
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        flex-direction: row;
        width: 75%;
        margin: auto; } }

.BrowserButton {
    align-self: center;
    width: 70%;
    display: -webkit-flex;
    -webkit-flex-wrap: wrap;
    display: flex;
    flex-wrap: wrap;
    padding: 10px 0;
    background-color: #fff;
    margin: 5px auto;
    box-shadow: 0 8px 16px 0 rgba(255, 255, 255, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }
@media all and (min-width: 1024px) {
    .BrowserButton {
        width: calc(25% - 10px);
        padding: 2vh 0; } }
.BrowserButton:hover {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); }

.BrowserButton__img {
    padding: 0 2%; }

.BrowserButton__img img {
    width: 55px;
    height: 55px;
    margin: 0 10px 5px; }

.BrowserButton__text {
    text-align: left; }

.BrowserButton__text p:nth-child(2) {
    color: #535353; }
/* Intitulé "Tous les rdv du ..." */

/* cards container */

.gridCal
{
    margin: 1rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
}

h2
{
    height: 20px;
    display: block;
}

/* card */
.cardCal
{
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.24);
    padding: 20px;
    border-radius: 15px;
    margin: 20px;
}

p.date
{
    text-align: right;
}

b.name
{
    font-size: 1.5rem;
}

span.phone
{
    margin-left: 2rem;
}

span.mail
{
    margin-left: 2rem;
}

/* p:not(.date) 
{
    color: black;
    font-size: 2rem;
} */

b.company
{
    font-size: 2rem;
}

/* .card > div > p
{

} */
.card > div > span
{
    color: #1EABD9;
    text-decoration: underline;
}

span.picked
{
    border-top: 1px solid rgba(0, 0, 0, 0.24);
    color: rgba(0, 0, 0, 0.24);
}

h2.intitule 
{
    grid-column: 1/-1;
    border-bottom: 1px solid black;
}

.cardCal > div 
{
    margin-bottom: 1rem;
    margin-top: 0.75rem;
}
.cardCal > div > p
{
    margin-bottom: 0;
}

.cardCal > i 
{
    vertical-align: middle;
}

.dateCal 
{
    text-align: right;
    font-size: 1.25rem;
}

.cardCal > p 
{
    margin: 1rem 0 -0.5rem;
}
.tdAndth {
    border: 1px solid rgb(190, 190, 190);
    padding: 10px;
}

.onlyTd {
    text-align: center;
}

.scrollit {
    overflow:scroll;
    height:500px;
}

/* tr:nth-child(even) {
    background-color: #eee;
}

th[scope="col"] {
    background-color: #696969;
    color: #fff;
}

th[scope="row"] {
    background-color: #d7d9f2;
} */

html, body, #root, .App, .App>div {
    height: 100%;
}

.header-background {
    min-height: 5%;
    background-color: #526AF2;
    display: flex;
    width: 100%;
    justify-content: center;
}

.header-company-name {
    font-weight: bold;
    font-size: 25px;
    margin-top: 1%;
    margin-bottom: 1%;
    color: white;
}

.Dropdown_dropdown__fVfEx {
  cursor: pointer;
  position: relative;
  width: 40%;
  margin-left: 2%;
  margin-right: 2%;
}

.Dropdown_dropdown__fVfEx select {
  color: black;
}

.Dropdown_dropdown__fVfEx select:hover {
  cursor: pointer;
}

.Dropdown_dropdown__fVfEx .Dropdown_options__nMMFy {
  color: black;
  font-family: 'Roboto';
  display: block;
  padding: 8px;
}

.Dropdown_dropdown__fVfEx .Dropdown_options__nMMFy:hover {
  background-color: undefined;
  cursor: pointer;
}

.Dropdown_icon__3VVFZ {
  margin-right: 8px;
  transition: color .3s;
}

.Dropdown_iconAnimate__3BmjC {
    transform: rotateZ(0deg);
    animation: Dropdown_rotate__1u1RT 0.3s linear;
    color: undefined;
}

.Dropdown_inputOption__3QYmH {
  color: black;
  background-color: white;
}

.Dropdown_placeholder__o9dFT {
  color: undefined;
}

.Dropdown_optionContainer__3ReyE {
  width: 100%;
  padding: 8px;
  margin-top: 8px;

  box-shadow: -1px 7px 9px 0px rgba(0, 0, 0, 0.1);
  border-radius: 5px;

  position: absolute;
  background-color: white;
  width: 100%;
  z-index: 1000;

  max-height: 150px;
  overflow: auto;
}

.Dropdown_optionContainer__3ReyE option {
  padding: 8px;
  border-radius: 5px;
}

.Dropdown_required__2ovrw {
  color: #4F67F3;
}

.Dropdown_error__24Km4 .Dropdown_hint__12NI3 {
  font-size: 12px;
  color: #DE330D;
}
@keyframes Dropdown_rotate__1u1RT {
  from {transform: rotateZ(180deg);}
  to {transform: rotateZ(0deg);}
}

.Input_input__G_-oQ {
  height: 42px;
  width: 100%;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  border-radius: 5px;
  color: #AEADBE;
  padding-left: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  font-family: 'Roboto', sans-serif;
}

.Input_input__G_-oQ.Input_error__jiKOW {
  border-color: #DE330D;
}

.Input_textarea__3jz0O {
  width: 100%;
  border: 1px solid #EFEFEF;
  box-sizing: border-box;
  border-radius: 5px;
  color: #AEADBE;
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: white;
}

.Input_input__G_-oQ .Input_ant-tooltip__eqJ39 {
  display: none;
}

.Input_input__G_-oQ input {
  border: none;
  height: 40px;
  width: 98%;
  box-sizing: border-box;
  outline: none;
  background: none;
  color: black;
  font-family: 'Roboto', sans-serif;
}

.Input_textarea__3jz0O textarea {
  border: none;
  height: 250px;
  width: 98%;
  margin: auto;
  box-sizing: border-box;
  outline: none;
  background: none;
  color: black;
  resize: none;
}

.Input_input__G_-oQ:focus-within, .Input_inputActive__3yPcj, .Input_input__G_-oQ:hover {
  border-color: #526AF2;
  outline: none;
  transition: .3s;
  cursor: pointer;
}

.Input_input__G_-oQ:focus-within {
  box-shadow: 0px 0px 6px #b1bdff;
}

.Input_label__uWmSf {
  margin-bottom: 8px;
  text-align: left;
}

.Input_hint__3qKAX {
  font-size: 12px;
  margin-top: 4px;
}

@media screen and (max-width: 450px) {
  .Input_input__G_-oQ {
    height: unset;
    min-height: 42px;
  }
}

.common-button {
    margin: 2% ;
    border-radius: 15px;
    height: 80%;
    width: 100%;
}

.primary {
    background-color: #404FA1;
    color: white;
}

.appointment-preview-container {
    background-color: white;
    border-radius: 25px;
    padding: 1%;
    margin: 1%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.appointment-preview-container:hover {
    box-shadow: 5px 5px 2px 0px rgba(0,0,0,0.75);
    cursor: pointer;
}

.appointment-list-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 15px;
}

.appointment-list-button-container {
    display: flex;
    justify-content: right;
}

.admin-page-container {
    width: 100%;
}

.admin-body-container {
    padding: 1%;
    display: flex;
    flex-direction: row;
}

.admin-body-column {
    width: 50%;
    padding: 2%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
}

.admin-calendar-handling-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.admin-filter-container {
    display: flex;
    width: 80%;
}

.admin-filter-dropdown {
    min-width: 20%;
}

.ayomi-calendar-button {
    flex-grow: 1;
}

.react-calendar {
    box-sizing: border-box;
    width: 100%;
}

.lighten-purple-background {
    background-color: #D4DAFC;
}

.template-editor-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0 0 0/ 75%);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.template-editor-modal {
    background-color: white;
    border-radius: 0.25rem;
    padding: 2rem;
    width: max-content;
}

.template-editor-type {
    background-color: #1A9BD3;
    color: white;
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    width: max-content;
    text-transform: uppercase;
}

.template-editor-content {
    display: flex;
    flex-direction: row;
    grid-gap: 1rem;
    gap: 1rem;
}

.template-editor-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    grid-gap: 1rem;
    gap: 1rem;
    margin-top: 1rem;
}

.template-editor-form {
    display: flex;
    flex-direction: column;
    grid-gap: 1rem;
    gap: 1rem;
}

.template-editor-label {
    display: flex;
    flex-direction: column;
    grid-gap: 0.25rem;
    gap: 0.25rem;
    width: 100%;
}

.template-editor-view {
    max-width: 320px;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;
    grid-gap: 0.5rem;
    gap: 0.5rem;
}

.template-editor-view > * {
    margin: 0;
}

.template-editor-code {
    font-size: 0.75rem;
    background-color: #eeeeee;
    padding: 1rem;
    border-radius: 0.5rem;
}

.success-message {
    color: #1f5721;
    background-color: #e5fce5;
    border-radius: 0.5rem;
    padding: 0.5rem;
    font-size: 0.75rem;
}

.error-message {
    color: #721c24;
    background-color: #f8d7da;
    border-radius: 0.5rem;
    padding: 0.5rem;
    font-size: 0.75rem;
}

.admin-template {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
}

.template-editor-hint {
    font-size: 0.75rem;
    color: #5d5c5c;
    margin-top: 0.5rem;
}

