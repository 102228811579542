/* Intitulé "Tous les rdv du ..." */

/* cards container */

.gridCal
{
    margin: 1rem;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 1rem;
}

h2
{
    height: 20px;
    display: block;
}

/* card */
.cardCal
{
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12), 0 8px 8px rgba(0, 0, 0, 0.24);
    padding: 20px;
    border-radius: 15px;
    margin: 20px;
}

p.date
{
    text-align: right;
}

b.name
{
    font-size: 1.5rem;
}

span.phone
{
    margin-left: 2rem;
}

span.mail
{
    margin-left: 2rem;
}

/* p:not(.date) 
{
    color: black;
    font-size: 2rem;
} */

b.company
{
    font-size: 2rem;
}

/* .card > div > p
{

} */
.card > div > span
{
    color: #1EABD9;
    text-decoration: underline;
}

span.picked
{
    border-top: 1px solid rgba(0, 0, 0, 0.24);
    color: rgba(0, 0, 0, 0.24);
}

h2.intitule 
{
    grid-column: 1/-1;
    border-bottom: 1px solid black;
}

.cardCal > div 
{
    margin-bottom: 1rem;
    margin-top: 0.75rem;
}
.cardCal > div > p
{
    margin-bottom: 0;
}

.cardCal > i 
{
    vertical-align: middle;
}

.dateCal 
{
    text-align: right;
    font-size: 1.25rem;
}

.cardCal > p 
{
    margin: 1rem 0 -0.5rem;
}