.template-editor-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0 0 0/ 75%);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.template-editor-modal {
    background-color: white;
    border-radius: 0.25rem;
    padding: 2rem;
    width: max-content;
}

.template-editor-type {
    background-color: #1A9BD3;
    color: white;
    font-weight: 500;
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
    border-radius: 1rem;
    width: max-content;
    text-transform: uppercase;
}

.template-editor-content {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.template-editor-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 1rem;
    margin-top: 1rem;
}

.template-editor-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.template-editor-label {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
}

.template-editor-view {
    max-width: 320px;
    padding: 1rem;
    border-radius: 0.5rem;
    border: 1px solid #eeeeee;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.template-editor-view > * {
    margin: 0;
}

.template-editor-code {
    font-size: 0.75rem;
    background-color: #eeeeee;
    padding: 1rem;
    border-radius: 0.5rem;
}

.success-message {
    color: #1f5721;
    background-color: #e5fce5;
    border-radius: 0.5rem;
    padding: 0.5rem;
    font-size: 0.75rem;
}

.error-message {
    color: #721c24;
    background-color: #f8d7da;
    border-radius: 0.5rem;
    padding: 0.5rem;
    font-size: 0.75rem;
}

.admin-template {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-top: 1rem;
}

.template-editor-hint {
    font-size: 0.75rem;
    color: #5d5c5c;
    margin-top: 0.5rem;
}
